.App {
  /* text-align: center; */
  margin: 0 5% 0 5%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 50vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #9761fb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.song:hover {
  background-color: rgba(255, 145, 77, 0.55);
}

.auth {
  height: 100vh;
}

.dashboard {
  height: 100vh;
}

.footer {
  bottom: 0;
  position: relative;
}
